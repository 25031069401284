@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  font-size: 16px;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
}

@media (max-width: 1280px) {
  html,
  body {
    font-size: 14px;
  }
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
}

/* Hide arrow icon from Number Input */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ReactTooltip */
div.ReactTooltip-opacity-1[id^=TooltipInteractive-] {
  opacity: 1;
}
